export default {
  'wittekamer': {
    name: 'wittekamer',
    description: 'Witte Kamer, 2019.',
    pictures: [
      { src: '1-patio-boom-overzicht', alt: '', title: '' },
      { src: '2-patio-ramen-gordijn', alt: '', title: '' },
      { src: '3-muur-dakraam-wit', alt: '', title: '' },
      { src: '4-gang-muur-wit', alt: '', title: '' },
      { src: '5-vloer-tegels-patroon', alt: '', title: '' },
      { src: '6-inkom-voorzetwand-verlichting', alt: '', title: '' },
      { src: '7-inkom-paal-verlichting', alt: '', title: '' }
    ]
  },
  'kolom': {
    name: 'kolom',
    description: 'Kolom, 2017.',
    pictures: [
      { src: 'detail-bottom', alt: '', title: '' },
      { description: '<p><em>Kolom</em> is een vrijstaande boekenkast.<br />Ze bestaat uit een moduleerbare houten structuur die op een betonnen sokkel pivoteert. In situ brengt het meubel een bepalende architecturale kwaliteit: de circulaire vorm doet denken aan een zuil, de horizontale onderverdeling biedt plaats voor het rangschikken van boeken.</p> <p>Vervaardigd uit Afromosiahout vormt deze opeenvolging van kruisen een ritmische en opwaartse geometrie die de mogelijkheid van een oneindige uitzetting suggereert. Ze rust op een betonnen voetstuk die als pivot en verbinding dient tussen de grond en de houten structuur. Het meubel vormt een microarchitectuur dat functie en vorm verheft tot een nieuwe harmonie.</p>' },
      { src: 'full-filled-rotating', alt: '', title: '' },
      { src: 'full-empty-straight', alt: '', title: '' },
      { src: 'full-filled-straight', alt: '', title: '' },
      { src: 'full-filled-rotated', alt: '', title: '' }
    ]
  },
  'folie-a-deux': {
    name: 'folieadeux',
    description: 'Folie à Deux, 2017.',
    pictures: [
      { src: 'minimalistic-tables-bench-glass-stairs', alt: '', title: '' },
      { description: '<p>Voor het inrichten van een koffiehuis in een voormalige burgerwoning, was het noodzakelijk om bepaalde elementen uit het interieur te halen en ontbrekende voorzieningen toe te voegen.</p> <p>We hebben deze twee handelingen echt als afzonderlijke interventies beschouwd. Ze afzonderlijk ontwerpen verduidelijkte meteen aan welke elementen er waarde wordt gehecht. Het weghalen stond voor ons steeds in functie van het beter laten uitkomen van bestaande delen maar het toont zich ook fysiek in de ruimte, met een massa en vaak ook kleuraanduiding.</p>' },
      { src: 'green-white-parquet-doors-ceiling', alt: '', title: '' },
      { src: 'detail-glass-frame-stairs', alt: '', title: '' },
      { description: '<p>Enkel waar het oorspronkelijke interieur voorzieningen miste werden elementen toegevoegd, die dan wel afstand houden van hun omgeving zodat ze een open interactie aangaan met de gebruiker. Het zijn vrijblijvende, haast abstracte volumes die hun gebruik subtiel suggereren. In geen geval zouden ze het oorspronkelijke interieur willen imiteren of beïnvloeden.</p> <p>We hopen dat door de manier waarop we weggenomen en toegevoegd hebben, er een omgeving is ontstaan die de uniciteit in ere houdt en uitnodigt om ze ongedwongen te gaan ontdekken.</p>' },
      { src: 'corner-marble-gray-mirror-frame-mantle', alt: '', title: '' },
      { src: 'green-door-ceiling-mirror-curtains', alt: '', title: '' },
      { src: 'detail-door-jamb-minimalistic-green-gray-moulures', alt: '', title: '' },
      { src: 'couches-bars-flowers', alt: '', title: '' },
      { src: 'tables-newspaper-coffee-glass', alt: '', title: '' }
    ]
  },
  'still': {
    name: 'still',
    description: 'Still, 2015.',
    pictures: [
      { src: 'top-surface', alt: '', title: '' },
      { description: '<p><em>Still</em> is altijd in beweging voor de aanschouwer.</p> <p>De structuur van rond staal lijkt toch telkens weer te ontsnappen aan zijn begrip ervan. Door rond het object te stappen verandert het beeld, terwijl de onderliggende structuur als een schim zichtbaar wordt.</p> <p>Zo complex de structuur, zo eenvoudig het tafelblad; een massief stuk hardhout met nét de juiste afmetingen op nét de juiste hoogte.</p>' },
      { src: 'frontal-left', alt: '', title: '' },
      { src: 'frontal-right', alt: '', title: '' }
    ]
  },
  'gloriette': {
    name: 'gloriette',
    description: 'Gloriette, 2015.',
    pictures: [
      { src: 'frontal-arches-wood-fence', alt: 'frontaal beeld van betonnen gewelf met houten terrasafsluiting', title: '' },
      { description: '<p><em>Gloriëtte</em> is een buitenruimte in het landelijke Poperinge, West-Vlaanderen.</p><p>Het is een poëtische plaats die zicht biedt op het landschap en bescherming tegen de wind.</p><p>Van betonnen structuur tot en met deurkruk, van conceptvorming tot uitvoeringsfase, is ze een lof aan het geborgen buiten te vertoeven.</p>' },
      { src: 'side-door-open-custom-handle', alt: '', title: '' },
      { src: 'view-landscape-framed', alt: '', title: '' },
      { src: 'detail-sun-glass-tiles', alt: '', title: '' },
      { src: 'garden-view-pond', alt: '', title: '' },
      { src: 'sunny-table-plants', alt: '', title: '' },
      { src: 'detail-reflection-pond-fence', alt: '', title: '' },
      { src: 'sunrise-through-glass-and-wood-fence', alt: '', title: '' }
    ]
  }
};
